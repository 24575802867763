import { UnformattedTextProperty } from '@edgebox/data-definition-kit';
import React from 'react';
import { ISyncCoreApiComponentState, SyncCoreApiComponent } from '../../../services/SyncCoreApiComponent';
import { ParamsComponent } from '../ParamsComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons/faCheckCircle';
import { faCircle } from '@fortawesome/pro-light-svg-icons/faCircle';
import { sendToParent } from '../../../frame-messages';

class BoxParams {
  @UnformattedTextProperty(false, 500)
  className?: string;
}

interface IProps {
  type: 'push' | 'pull';
}

interface IState extends ISyncCoreApiComponentState {
  count?: number;
}

export class OnboardingConfiguration extends SyncCoreApiComponent<IProps, IState> {
  async load() {
    const { type } = this.props;

    const response = await this.api.syndication.flows.search(
      {
        type,
      },
      {
        itemsPerPage: 0,
      }
    );

    sendToParent({
      type: 'count',
      count: response.totalNumberOfItems,
      entityType: 'flow',
    });

    return {
      count: response.totalNumberOfItems,
    };
  }

  render() {
    const { count } = this.state;
    const { type } = this.props;

    if (typeof count !== 'number') {
      return this.renderRequest();
    }

    return (
      <ParamsComponent<BoxParams> Params={BoxParams}>
        {(params: BoxParams) => (
          <div className={params.className}>
            <FontAwesomeIcon icon={count > 0 ? faCheckCircle : faCircle} className={count > 0 ? 'text-success' : 'text-light'} /> {count}{' '}
            Flows configured to {type}
          </div>
        )}
      </ParamsComponent>
    );
  }
}
