import { UnformattedTextProperty } from '@edgebox/data-definition-kit';
import React from 'react';
import { ISyncCoreApiComponentState, SyncCoreApiComponent } from '../../../services/SyncCoreApiComponent';
import { ParamsComponent } from '../ParamsComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons/faCheckCircle';
import { faCircle } from '@fortawesome/pro-light-svg-icons/faCircle';
import { sendToParent } from '../../../frame-messages';
import { MigrationType, SyndicationStatus } from '@edgebox/sync-core-data-definitions';

class BoxParams {
  @UnformattedTextProperty(false, 500)
  className?: string;
}

interface IProps {
  entityType: 'syndication' | 'migration';
  type: 'push' | 'pull';
}

interface IState extends ISyncCoreApiComponentState {
  count?: number;
}

export class OnboardingSyndication extends SyncCoreApiComponent<IProps, IState> {
  async load() {
    const { type, entityType } = this.props;

    const response =
      entityType === 'migration'
        ? await this.api.syndication.migrations.list(
            {
              types:
                type === 'push'
                  ? [MigrationType.PushAll, MigrationType.PushAllLatest]
                  : [MigrationType.PullAll, MigrationType.PullChanged, MigrationType.MapExistingById],
              statuses: [SyndicationStatus.Finished],
            },
            { itemsPerPage: 0 }
          )
        : await this.api.syndication.syndications.list(
            {
              isUpdate: type === 'push',
              isRegularSyndication: true,
              statuses: [SyndicationStatus.Finished],
            },
            {
              itemsPerPage: 0,
            }
          );

    sendToParent({
      type: 'count',
      count: response.totalNumberOfItems,
      entityType,
    });

    return {
      count: response.totalNumberOfItems,
    };
  }

  render() {
    const { count } = this.state;
    const { type, entityType } = this.props;

    if (typeof count !== 'number') {
      return this.renderRequest();
    }

    return (
      <ParamsComponent<BoxParams> Params={BoxParams}>
        {(params: BoxParams) => (
          <div className={params.className}>
            <FontAwesomeIcon icon={count > 0 ? faCheckCircle : faCircle} className={count > 0 ? 'text-success' : 'text-light'} /> {count}{' '}
            {entityType === 'migration' ? 'mass ' : ''}
            {type === 'push' ? 'pushes' : 'pulls'}
          </div>
        )}
      </ParamsComponent>
    );
  }
}
